import {
  CREATED_USER_FILTERS,
  RETRIEVED_RECIPE,
  RETRIEVED_RECIPES,
  TOGGLE_IS_USER_RECIPE,
  TOGGLE_VOTE
} from "../../common/consts";
import isEmpty from "lodash/isEmpty";
import findIndex from "lodash/findIndex";
import { checkInvalidSessionID, debug } from "../../common/helpers";
import ReactGA from "react-ga";

const recipesData = (state = {}, action = {}) => {
  //console.log("Recipes Reducers", state, action);
  let _return = {};

  if (checkInvalidSessionID({action})) {
    return {
      ...state
    };
  }

  switch (action.type) {
    case RETRIEVED_RECIPES:
      debug.log("retrieved recipes", action);
      debug.log("state recipes", state.recipes);

      return {
        ...state,
        // if we don't already have recipes OR if the current page is 1 then reset the state recipes
        // otherwise, concat the state recipes with the newly loaded recipes because we're using load more
        recipes: (action.criteria.response.load_more && state.recipes) ? state.recipes.concat(action.criteria.response.recipes) : action.criteria.response.recipes,
        //recipes: (state.recipes === undefined || action.recipeCriteria.response.current_page === 1) ? action.recipeCriteria.response.recipes : state.recipes.concat(action.recipeCriteria.response.recipes),
        filters: (action.criteria.response.filters !== null) ? action.criteria.response.filters : state.filters,
        selectedFilters: (action.criteria.response.selected_filters !== null) ? action.criteria.response.selected_filters : [],
        totalPages: action.criteria.response.total_pages,
        totalRecipes: action.criteria.response.total_recipes,
        time: action.criteria.response.time
      };

    case RETRIEVED_RECIPE:
      console.log("retrieved recipe", action);

      if (!isEmpty(action.criteria.response.recipes)) {
        if (process.env.NODE_ENV !== "development") {
          ReactGA.event({
            category: "Recipe",
            action: `View: ${action.criteria.response.recipes[0].id}`,
          });
        }
      }
      return {
        ...state,
        recipe: !isEmpty(action.criteria.response.recipes) ? action.criteria.response.recipes[0] : [],
        time: action.criteria.response.time
        //filters: (action.recipeCriteria.response.filters !== null) ? action.recipeCriteria.response.filters : state.filters,
        //totalPages: action.recipeCriteria.response.total_pages,
        //totalRecipes: action.recipeCriteria.response.total_recipes
      };

    case TOGGLE_VOTE:
      debug.log("toggle vote", state, action);

      // here we look for the recipe in the global recipes and update the recipe vote property
      // so that anything that depends on it will instantly be updated
      if (!isEmpty(state.recipes)) {
        const recipeIndex = findIndex(state.recipes, ["id", action.criteria.id]);
        //debug.log("recipe index", recipeIndex);
        if (recipeIndex > -1) {
          state.recipes[recipeIndex].votes = action.criteria.votes;
        }
      }

      return {
        ...state,
      };

    case TOGGLE_IS_USER_RECIPE:
      //debug.log("toggle is user recipe", state, action);

      // here we look for the recipe in the global recipes and update the recipe is_user_recipe property
      // so that anything that depends on it will instantly be updated
      if (!isEmpty(state.recipes)) {
        const recipeIndex = findIndex(state.recipes, ["id", action.criteria.id]);
        //debug.log("recipe index", recipeIndex);
        if (recipeIndex > -1) {
          state.recipes[recipeIndex].is_user_recipe = action.criteria.is_user_recipe;
        }
      }

      return {
        ...state,
      };

    case CREATED_USER_FILTERS:
      //console.log("reducer: user tool - created", action);
      debug.log("reducer: recipes - user filters - created", action);

      _return = {
        ...state,
        time: action.criteria.response.time
      };

      if (action.criteria.response.filters !== null && _return.filters !== null) {
        // we want to recreate the filters object otherwise it won't fire the useEffect in the ListFilters component
        _return.filters = {
          ..._return.filters,
          user_filters: action.criteria.response.filters
        };
      }
      //debug.log(_return, "reducer: recipes - user filters - created - return");

      return _return;

    // return {
    //   ...state,
    //   filters: (action.criteria.response.filters !== null) ? action.criteria.response.filters : state.filters,
    //
    //   //is_user_tool: action.criteria.result === "success",
    //   user_filters: action.criteria.response.filters,
    // };

    default:
      return state;
  }
};
export default recipesData;