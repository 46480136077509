import classNames from 'classnames/bind';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { Container, Responsive, Visibility } from 'semantic-ui-react';
import WebFont from 'webfontloader';
import { debug, forceLogout, isAuthenticated } from './common/helpers';
import PrivateRoute from './common/PrivateRoute';
import { retrieveSettings, updateSettings } from './redux/actions/settings';
import { retrieveUser, retrieveUserStatus } from './redux/actions/user';
import Account from './screens/account/Account';
import Groups from './screens/account/Groups';
import ForgotPassword from './screens/account/ForgotPassword';
import FreeSignUp from './screens/account/FreeSignUp';
import LogIn from './screens/account/LogIn';
import LogOut from './screens/account/LogOut';
import ResetPassword from './screens/account/ResetPassword';
import SignUp from './screens/account/SignUp';
import UserSurvey from './screens/account/UserSurvey';
import ExitSurvey from './screens/account/ExitSurvey';
import FAQ from './screens/faq/FAQ';
import ContactUs from './screens/global/ContactUs';
import Home from './screens/home/Home';
import HTML from './screens/misc/HTML';
import Recipe from './screens/recipes/Recipe';
import RecipeIssue from './screens/recipes/RecipeIssue';
import RecipeRequest from './screens/recipes/RecipeRequest';
import Recipes from './screens/recipes/Recipes';
import Parts from './screens/parts/Parts';
import Part from './screens/parts/Part';
import './styles/App.scss';
import CacheBuster from './common/CacheBuster';
import Embedded from './screens/misc/Embedded';
import ReactGA from 'react-ga';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import TagManager from 'react-gtm-module';
import store from 'store';
import Calculator from './screens/recipes/Calculator';
import { HEARTBEAT_TIMER_INTERVAL } from './common/consts';

//https://www.npmjs.com/package/webfontloader#get-started
WebFont.load({
  google: {
    families: ['Roboto:300,400,500,700', 'sans-serif']
  },
});

let user = null;

// https://levelup.gitconnected.com/using-google-analytics-with-react-3d98d709399b
if (process.env.NODE_ENV !== 'development') {
  ReactGA.initialize('UA-141676230-2');
  ReactGA.pageview(window.location.pathname + window.location.search);

  if (isAuthenticated()) {
    user = store.get('user');

    ReactGA.set({
      userId: user.uid,
      // any data that is relevant to the user session
      // that you would like to track with google analytics
    });
  }

  // https://www.npmjs.com/package/react-gtm-module
  const tagManagerArgs = {
    gtmId: 'AW-704227103'
  };
  TagManager.initialize(tagManagerArgs);
}

//const My = lazy(() => import("./my"));
//import logo from './logo.svg';
//import './App.css';

function App(props) {
  const { retrieveSettings, retrieveUser, retrieveUserStatus } = props;
  const { trackPageView } = useMatomo();

  useEffect(() => {
    // console.log("useEffect: App", props);
    debug.log('loading default settings');
    // ********* HERE WE'RE LOADING THE DEFAULT SETTINGS ********* //
    retrieveSettings();
    // ********* HERE WE'RE LOADING THE USER ********* //
    retrieveUser();

    if (isAuthenticated()) {
      const user = store.get('user');
      debug.log('matomo tracking with authenticated user', user);

      const heartbeatTimer = setInterval(() => {
        debug.log('heartbeat ');
        // disabled for now 1/3/22
        // retrieveUserStatus();
        //clearInterval(heartbeatTimer);
      }, 1000 * HEARTBEAT_TIMER_INTERVAL);
      updateSettings({ heartbeatTimer });

      window._paq.push(['setUserId', `${user.fullname} (${user.uid})`]);
      window._paq.push(['setCustomVariable', 1, 'uid', user.uid, 'visit']);
      window._paq.push(['setCustomVariable', 2, 'tier', user.tier, 'visit']);
      window._paq.push(['setCustomVariable', 3, 'name', user.fullname, 'visit']);
      window._paq.push(['setCustomVariable', 4, 'email', user.email, 'visit']);
      window._paq.push(['setCustomVariable', 5, 'version', global.appVersion, 'visit']);
    } else {
      // anonymous
    }
    trackPageView();
  }, []);

  const hideFixedMenu = () => this.setState({ fixed: false });
  const showFixedMenu = () => this.setState({ fixed: true });
  const getWidth = () => {
    //const isSSR = typeof window === "undefined";
    //console.log("IsSSR", isSSR);
    //return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
    return Responsive.onlyTablet.minWidth;
  };

  //console.log("location", props.location);
  // const parsedURLParams = queryString.parse(props.location.search);
  // console.log("params", parsedURLParams);

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }

        forceLogout();
        return (
          <div
            className={classNames('page', {
              'is-authenticated': isAuthenticated(),
              'is-not-authenticated': !isAuthenticated()
            })}>
            <Responsive
              getWidth={getWidth}
              minWidth={Responsive.onlyMobile.minWidth}>
              <Visibility
                once={false}
                onBottomPassed={showFixedMenu}
                onBottomPassedReverse={hideFixedMenu}
              >
                <Container>
                  <React.Fragment>
                    {/* <Route location={location} path="/" exact component={CreatorShopPage} /> */}
                    {/* console.log('app keys', this.props.keysData) */}
                    <Switch>
                      <Route
                        path="/"
                        exact
                        component={Home}
                      />
                      <Route
                        path="/faq"
                        exact
                        component={FAQ}
                      />
                      <Route
                        path="/privacy-policy"
                        exact
                        component={() => <HTML
                          propertyName="privacy_policy"
                          menuItem="privacy_policy"
                          header="Privacy Policy"/>}
                      />
                      <Route
                        path="/terms-of-service"
                        exact
                        component={() => <HTML
                          propertyName="terms_of_service"
                          menuItem="terms_of_service"
                          header="Terms of Service"/>}
                      />
                      <Route
                        path="/login"
                        exact
                        component={LogIn}
                      />
                      <Route
                        path="/logout"
                        exact
                        component={LogOut}
                      />
                      <Route
                        path="/forgot-password"
                        exact
                        component={ForgotPassword}
                      />
                      <Route
                        path="/reset-password"
                        exact
                        component={ResetPassword}
                      />
                      <Route
                        path="/join"
                        exact
                        component={SignUp}
                      />
                      <Route
                        path="/test-drive"
                        exact
                        component={FreeSignUp}
                      />
                      <Route
                        path="/exit-survey"
                        exact
                        component={ExitSurvey}
                      />
                      <Route
                        path="/contact-us"
                        exact
                        component={ContactUs}
                      />
                      <Route
                        path="/news"
                        exact
                        component={() => <Embedded
                          page="/news"
                          path="news"
                          activeMenuItem="news"/>}
                      />
                      <Route
                        path="/news/:articleSlug"
                        exact
                        component={() => <Embedded
                          page="/news"
                          path="news"
                          activeMenuItem="news"/>}
                      />
                      <Route
                        path="/news/page/:pageNumber"
                        exact
                        component={() => <Embedded
                          page="/news"
                          path="news"
                          activeMenuItem="news"/>}
                      />

                      <PrivateRoute
                        minTier="0"
                        exact
                        path="/recipes"
                        component={Recipes}/>
                      <PrivateRoute
                        minTier="0"
                        exact
                        path="/recipe/:recipeID"
                        component={Recipe}/>
                      <PrivateRoute
                        minTier="1"
                        exact
                        path="/calculator"
                        component={Calculator}/>
                      <PrivateRoute
                        minTier="1"
                        exact
                        path="/calculator/:recipeID"
                        component={Calculator}/>
                      <PrivateRoute
                        minTier="0"
                        exact
                        path="/parts"
                        component={Parts}/>
                      <PrivateRoute
                        minTier="0"
                        exact
                        path="/part/:partID"
                        component={Part}/>
                      <PrivateRoute
                        minTier="1"
                        bypassLock={true}
                        exact
                        path="/account"
                        component={Account}/>
                      <PrivateRoute
                        minTier="1"
                        exact
                        path="/manage-groups"
                        component={Groups}/>
                      <PrivateRoute
                        minTier="1"
                        exact
                        path="/report-recipe-issue"
                        component={RecipeIssue}/>
                      <PrivateRoute
                        minTier="1"
                        exact
                        path="/recipe-request"
                        component={RecipeRequest}/>
                      <PrivateRoute
                        minTier="1"
                        exact
                        path="/submit-recipe"
                        component={() => <HTML
                          propertyName="submit_recipe"
                          menuItem="submit_recipe"
                          header="Submit Recipe"/>}/>
                      <PrivateRoute
                        minTier="1"
                        exact
                        path="/member-survey"
                        component={UserSurvey}/>
                      <Redirect to="/"/>
                    </Switch>
                  </React.Fragment>
                </Container>
              </Visibility>
            </Responsive>
          </div>
        );
      }}
    </CacheBuster>
  );
}

//  <div className="App"><Recipes/></div>
function mapStateToProps(state) {
  //console.log("App: Map State to Props", state);
  return {
    settings: state.settings,
  };
}

export default connect(mapStateToProps, { retrieveSettings, retrieveUser, retrieveUserStatus })(withRouter(App));